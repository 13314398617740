export const orderTypeLabels = {
  0: 'None',
  1: 'Website',
  2: 'Trade Center',
  3: 'Phone/Email',
  4: 'Mobile App',
  5: 'EDI'
}

export const orderStatusData = {
  0: { variant: 'primary', label: 'PROCESSING' },
  1: { variant: 'warning-2', label: 'PARTIALLY DESPATCHED' },
  2: { variant: 'success', label: 'DESPATCHED' },
  3: { variant: 'danger', label: 'CANCELLED' }
}
