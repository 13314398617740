import Link from 'next/link'
import Router from 'next/router'
import React, { FC, useState } from 'react'
import { Alert, Button, CheckBox, TextField, useReCaptcha } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { Form, Formik } from 'formik'
import { useResendActivationLink } from 'lib/actions/auth'
import { useSignIn } from 'lib/actions/auth/signIn'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import { LoginSchema } from 'lib/schemas/login'

import { PasswordInput } from 'components/atoms'
import { OnScreenMessage } from 'components/molecules'

import { TooManyAttempts } from './TooManyAttempts'

export interface ILoginProps {
  className?: string
  disableRedirect?: boolean
  hideHeader?: boolean
  hideRememberMe?: boolean
  hideCreateAccount?: boolean
  isFasterCheckout?: boolean
  onLogin?: () => void
}

export interface IFormValues {
  username: string
  password: string
  isCaptchaVerified: boolean
  captchaToken: string
  rememberMe?: boolean
}

export interface IInitialErrors {
  hasError: boolean
  message: string
}

export const LoginForm: FC<ILoginProps> = ({
  className,
  disableRedirect = false,
  hideCreateAccount,
  hideHeader,
  hideRememberMe,
  isFasterCheckout,
  onLogin,
  ...restProps
}: ILoginProps) => {
  const LoginClasses = CN(`login w-full flex flex-col gap-[24px]`, className)

  /* Context */
  const { user } = useAppContext()
  const { loginModal, forgotPasswordModal, signUpModal } = useModalContext()

  /* Hooks */
  const { signIn, isPending: signInLoading } = useSignIn()
  const { generateToken } = useReCaptcha()

  /* States */
  const [invalidAccountDetails, setInvalidAccountDetails] = useState(false)
  const [tooManyAttempts, setTooManyAttempts] = useState(false)
  const [accountActivationPending, setAccountActivationPending] = useState(false)
  const [emailAddressNotFound, setEmailAddressNotFound] = useState(false)
  const [lockedTime, setLockedTime] = useState('')

  /* Setting initial formik values */
  const initialFormValues: IFormValues = {
    username: '',
    password: '',
    rememberMe: true,
    isCaptchaVerified: true,
    captchaToken: ''
  }

  /* Setting initial errors object for showing toasters and alerts */
  const initialErrors: IInitialErrors = {
    hasError: false,
    message: ''
  }

  const [error, setError] = useState(initialErrors)

  /* Reset local errors */
  const resetErrors = () => {
    setError(initialErrors)
    setInvalidAccountDetails(false)
    setAccountActivationPending(false)
    setTooManyAttempts(false)
    setLockedTime('')
  }

  /* Handle form fields on change event */
  const handleOnChange = ({ e, key, cb }: any) => {
    resetErrors() // Reset errors
    setError(initialErrors) // Setting initial errors object (making it empty)
    cb(key)(e) // Calling formik's handleChange method
  }

  /* Handle form submit */
  const handleSignIn = async (values: IFormValues) => {
    /* Reset errors */
    resetErrors()

    /* Generate G-captcha token */
    const token = await generateToken()

    /* Trigger signIn mutate hook for the API call */
    const { messageType, messageTitle, data } = await signIn({ ...values, captchaToken: token })

    /* If success */
    if (messageType === 'success') {
      if (onLogin) {
        onLogin()
      }

      const isRedirect = Router.query?.isRedirect ?? 'true'

      const redirectUrl = data?.data?.roles?.includes('FULL_TRADE') ? '/' : '/account'

      if (isRedirect === 'true' && !disableRedirect) {
        Router.push(redirectUrl)
      }

      if (!hideHeader) {
        loginModal?.close()
      }

      return
    }

    /*
     * TODO: Properly handle "Too many attempts" error with backend team consultation
     */

    /* Handle user error, warning, and other cases */
    switch (messageType) {
      case 'error':
        if (messageTitle === 'Invalid username or password.') {
          return setInvalidAccountDetails(true)
        } else if (messageTitle === 'Your account has been locked.') {
          setLockedTime(data?.lockedEndTime)
          setTooManyAttempts(true)
        } else {
          return setError({ hasError: true, message: messageTitle })
        }
        break
      case 'info':
        if (messageTitle === 'The provided email address is invalid') {
          return setEmailAddressNotFound(true)
        } else {
          return setError({ hasError: true, message: messageTitle })
        }
      case 'warning':
        if (messageTitle === 'Your account has been not activated yet.') {
          return setAccountActivationPending(true)
        } else {
          return setError({ hasError: true, message: messageTitle })
        }
      default:
        break
    }

    /* If system or fallback error */
    return setError({ hasError: true, message: 'System error, please try again later.' })
  }

  const [resendLinkActivationStatus, setResendLinkActivationStatus] = useState(false)

  const { resendActivationLinkAsync, data: resendActivationLinkData } = useResendActivationLink()

  const resendActivationLink = async (emailAddress: string) => {
    const response = await resendActivationLinkAsync({
      emailAddress
    })
    if (response?.messageType === 'success') {
      resetErrors()
      setResendLinkActivationStatus(true)
    }
  }

  /* Render username field hint */
  const renderUsernameHint = ({ hintError, emailAddress }: any) => {
    if (accountActivationPending) {
      return (
        <span>
          Your online account is not activated.{' '}
          <a
            role='button'
            tabIndex={0}
            data-component-id='resend-activation-link'
            onClick={() => {
              resendActivationLink(emailAddress)
            }}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                resendActivationLink(emailAddress)
              }
            }}
            className='underline pl-[2px] cursor-pointer font-500'>
            Resend Activation Link
          </a>
        </span>
      )
    }

    if (emailAddressNotFound) {
      return (
        <span>
          Please check your email and try again or{' '}
          <Link
            href='/auth/register'
            onClick={loginModal.close}
            data-component-id='create-account-link'
            className='underline font-500'>
            Create Account
          </Link>
        </span>
      )
    }

    return hintError
  }

  const onForgotPasswordClick = () => {
    if (Router.pathname === '/auth/login' && !loginModal.isOpen) {
      Router.push('/auth/forgotPassword')
    } else {
      loginModal?.close()
      forgotPasswordModal?.open()
    }
  }

  return (
    <div data-component-id='sign-in' className={LoginClasses} {...restProps}>
      {!resendLinkActivationStatus && !tooManyAttempts && (
        <>
          {/* Heading */}
          {!hideHeader && (
            <div className='login__heading flex flex-col'>
              <h3
                data-component-id='sign-in-header'
                className='text-N-700 text-display-h2 font-display uppercase'>
                Sign In
              </h3>
              <span className='text-N-500 text-base'>to your online account</span>
            </div>
          )}

          {/* Form */}
          {user?.account?.status !== 'locked' && (
            <Formik
              validateOnChange
              initialValues={initialFormValues}
              validationSchema={LoginSchema}
              onSubmit={handleSignIn}>
              {({
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                values,
                isInitialValid,
                isValid,
                isSubmitting
              }) => {
                return (
                  <Form>
                    <div className='flex flex-col gap-[20px] w-full'>
                      <div
                        className={CN('login__fields flex gap-[20px] w-full', {
                          'flex-col md:flex-row': isFasterCheckout,
                          'flex-col': !isFasterCheckout
                        })}>
                        <TextField
                          id='username'
                          componentId='username'
                          className='w-full'
                          wrapperClassName='w-full'
                          inputSize='md'
                          label='Email Address'
                          required
                          value={values.username}
                          hint={renderUsernameHint({
                            hintError: errors.username,
                            emailAddress: values.username
                          })}
                          hasError={
                            invalidAccountDetails ||
                            accountActivationPending ||
                            emailAddressNotFound ||
                            !!errors.username
                          }
                          onChange={e => {
                            handleOnChange({ e, key: 'username', cb: handleChange })
                            setEmailAddressNotFound(false)
                          }}
                          onBlur={handleBlur('username')}
                        />

                        <PasswordInput
                          id='password'
                          componentId='password'
                          className='w-full'
                          wrapperClassName='w-full'
                          hint={errors.password && touched.password ? errors.password : ''}
                          hasError={
                            invalidAccountDetails || !!(errors.password && touched.password)
                          }
                          inputSize='md'
                          label='Password'
                          placeholder=''
                          required
                          value={values.password}
                          onChange={(e: any) => {
                            handleOnChange({ e, key: 'password', cb: handleChange })
                          }}
                          onBlur={handleBlur('password')}
                        />
                      </div>

                      {error?.hasError && (
                        <Alert
                          alertHeader={error?.message}
                          isCloseIcon={false}
                          status='Error'
                          variant='inline'
                        />
                      )}

                      {invalidAccountDetails && (
                        <Alert
                          alertHeader='Please check your email or password and try again'
                          isCloseIcon={false}
                          status='Error'
                          variant='inline'
                        />
                      )}
                      <span
                        className={CN({
                          'flex flex-col gap-5': !isFasterCheckout,
                          'flex flex-row justify-between': isFasterCheckout
                        })}>
                        <div
                          className={CN({
                            'flex flex-col items-start gap-[12px] sm:!flex-row xs:justify-between':
                              !hideRememberMe
                          })}>
                          {!hideRememberMe && (
                            <CheckBox
                              componentId='sign-in-remember-me'
                              checked={values.rememberMe || false}
                              label='Remember me'
                              id={undefined}
                              onChange={e => {
                                handleOnChange({ e, key: 'rememberMe', cb: handleChange })
                              }}
                            />
                          )}
                          <span className='text-O-500 text-base font-medium'>
                            <Button
                              componentId='sign-in-forgot-password'
                              appearance='link-gray'
                              size='md'
                              onClick={onForgotPasswordClick}>
                              Forgot Your Password?
                            </Button>
                          </span>
                        </div>

                        <div className='flex flex-col gap-[28px]'>
                          <Button
                            componentId='sign-in-submit'
                            onClick={handleSubmit}
                            size='md'
                            appearance='primary-orange'
                            isLoading={signInLoading}
                            disabled={(!isValid && !isInitialValid) || isSubmitting}>
                            SIGN IN
                          </Button>
                          {!hideCreateAccount && (
                            <div className='flex flex-col gap-[8px]'>
                              <span className='text-base text-N-700 font-medium'>
                                Don’t have an online account?
                              </span>

                              <Button
                                componentId='sign-in-create-account'
                                appearance='secondary-orange'
                                size='md'
                                onClick={() => {
                                  loginModal?.close()
                                  setTimeout(() => {
                                    signUpModal?.open()
                                  }, 500)
                                }}>
                                CREATE ACCOUNT
                              </Button>
                            </div>
                          )}
                        </div>
                      </span>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          )}
        </>
      )}

      {!resendLinkActivationStatus && tooManyAttempts && (
        <TooManyAttempts lockedEndTime={lockedTime} onReTry={() => resetErrors()} />
      )}

      {resendLinkActivationStatus && (
        <OnScreenMessage
          className=''
          header={resendActivationLinkData.messageType === 'success' ? 'Success' : 'Attention!'}
          type={resendActivationLinkData.messageType}
          subHeader={resendActivationLinkData.messageTitle}
          description={resendActivationLinkData.messageDescription}
        />
      )}
    </div>
  )
}

export default LoginForm
