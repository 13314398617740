import React from 'react'
import { GoogleCaptcha } from '@nzsb/shopnx-ui'
import { useQueryClient } from '@tanstack/react-query'
import { useGetCart } from 'lib/actions'
import { useSession } from 'lib/actions/session/getSession'

import { LoginForm } from 'components/forms/Login/LoginForm'

export const FastSignIn: React.FC = () => {
  const { getSession } = useSession()

  const queryClient = useQueryClient()

  const { refetch: fetchCart } = useGetCart()

  const onLoginCallBack = async () => {
    await getSession()
    await fetchCart()
    await queryClient.invalidateQueries({ queryKey: ['GET_CART'] })
    await queryClient.invalidateQueries({ queryKey: ['GET_CART_ITEMS'] })
  }

  return (
    <GoogleCaptcha className='w-full' reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_SITE_KEY}>
      <div className='flex flex-col gap-6 bg-B-25 p-4 rounded'>
        <h3 className='text-h3 text-N-800 font-semibold'>Sign In</h3>
        <LoginForm
          hideHeader
          hideCreateAccount
          hideRememberMe
          isFasterCheckout
          onLogin={onLoginCallBack}
          disableRedirect
        />
      </div>
    </GoogleCaptcha>
  )
}

export default FastSignIn
