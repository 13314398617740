import { useQuery } from '@tanstack/react-query'
import { useAppContext } from 'lib/contexts/app-context'
import { httpGET } from 'lib/http'

/* API Request */
export const getCustomerDetails = async (context?: any) => {
  try {
    const { data: response } = await httpGET({
      url: 'account/GetCustomer',
      context
    })

    return response
  } catch (error) {
    return { error }
  }
}

/* Client side react-query custom hook */
export const useCustomer = () => {
  const { isLoggedIn } = useAppContext()
  const { refetch: getCustomer, ...restOptions } = useQuery({
    queryKey: ['customer'],
    enabled: isLoggedIn,
    queryFn: getCustomerDetails
  })

  // Passing mutateAsync as link To CreditAccount
  // and restOptions as rest of the options
  return { getCustomer, ...restOptions }
}
