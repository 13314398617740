import React, { FC, useEffect, useState } from 'react'
import { CheckBox } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useFormikContext } from 'formik'
import { IFormValues } from 'pages/checkout'
import { useGetCustomerAddresses } from 'lib/actions'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'

import AddressBlock from 'components/forms/WorksafeTrainingEnrollment/AddressBlock'

import { AddressCard, AddressProps } from '../AddressCard'

export interface IInvoiceAddressBlockProps {
  className?: string
  componentId?: string
  isNoStoresToPickup?: boolean
}

export interface IInvoiceAddressFormValues {
  invoiceAddress: {
    attentionTo: string
    city: string
    postcode: string
    searchingAddress: string
    streetAddress: string
    suburb: string
  }
}

export const InvoiceAddressBlock: FC<IInvoiceAddressBlockProps> = ({
  className,
  componentId,
  isNoStoresToPickup = false,
  ...restProps
}: IInvoiceAddressBlockProps) => {
  const { setFieldValue, touched, values } = useFormikContext<IFormValues>()
  const { user } = useAppContext()
  const { searchAddressModal } = useModalContext()
  const [userType, setUserType] = useState<'guest' | 'webcard' | 'linked'>('guest')
  const [invoiceAddress, setInvoiceAddress] = useState({
    attentionTo: '',
    searchingAddress: '',
    streetAddress: '',
    suburb: '',
    city: '',
    postcode: ''
  })
  const [initInvoiceAddress, setInitInvoiceAddress] = useState<AddressProps>()

  const [isSameAddress, setIsSameAddress] = useState(true)

  const invoiceAddressAttentionTo = 'invoiceAddress.attentionTo'
  const invoiceAddressStreetAddress = 'invoiceAddress.streetAddress'
  const invoiceAddressSearchingAddress = 'invoiceAddress.searchingAddress'
  const invoiceAddressSuburb = 'invoiceAddress.suburb'
  const invoiceAddressCity = 'invoiceAddress.city'
  const invoiceAddressPostcode = 'invoiceAddress.postcode'

  const { open, setAddressDetails, setSaveFn, setTitle } = searchAddressModal

  useEffect(() => {
    if (
      user?.data?.roles?.includes('FULL_TRADE') ||
      user?.data?.roles?.includes('LIMITED_TRADE') ||
      user?.data?.roles?.includes('PUNCH_OUT_USER')
    ) {
      setUserType('linked')
    } else if (user?.data?.roles?.includes('NON_TRADE')) {
      setUserType('webcard')
    } else {
      setUserType('guest')
    }
  }, [user])

  const getSearchingAddress = (
    streetAddress?: string,
    suburb?: string,
    city?: string,
    postcode?: string
  ) => {
    const addressParts = [streetAddress, suburb, city, postcode].filter(Boolean)
    return addressParts.join(', ')
  }

  const { data: cusAddressData } = useGetCustomerAddresses()

  useEffect(() => {
    if (cusAddressData) {
      const initInvoiceAddressValues = cusAddressData?.data?.customerAddresses?.filter(
        (address: any) => address.isInvoiceAddress
      )

      if (initInvoiceAddressValues?.length > 0) {
        setFieldValue(
          `${invoiceAddressSearchingAddress}`,
          getSearchingAddress(
            initInvoiceAddressValues[0].streetAddress,
            initInvoiceAddressValues[0].suburb,
            initInvoiceAddressValues[0].city,
            initInvoiceAddressValues[0].postCode
          )
        )

        setFieldValue(`${invoiceAddressAttentionTo}`, initInvoiceAddressValues[0].attentionTo)

        setFieldValue(`${invoiceAddressStreetAddress}`, initInvoiceAddressValues[0].streetAddress)

        setFieldValue(`${invoiceAddressSuburb}`, initInvoiceAddressValues[0].suburb)

        setFieldValue(`${invoiceAddressCity}`, initInvoiceAddressValues[0].city)

        setFieldValue(`${invoiceAddressPostcode}`, initInvoiceAddressValues[0].postCode)
      }

      if (initInvoiceAddressValues?.length > 0 && userType === 'linked') {
        setInitInvoiceAddress(initInvoiceAddressValues[0])
      } else if (initInvoiceAddressValues?.length > 0 && userType === 'webcard') {
        setInvoiceAddress({
          searchingAddress: getSearchingAddress(
            initInvoiceAddressValues[0].streetAddress,
            initInvoiceAddressValues[0].suburb,
            initInvoiceAddressValues[0].city,
            initInvoiceAddressValues[0].postCode
          ),
          attentionTo: initInvoiceAddressValues[0].attentionTo,
          streetAddress: initInvoiceAddressValues[0].streetAddress,
          suburb: initInvoiceAddressValues[0].suburb,
          city: initInvoiceAddressValues[0].city,
          postcode: initInvoiceAddressValues[0].postCode
        })
      }
    }
  }, [cusAddressData, userType])

  const handleInvoiceAddressClick = () => {
    if (invoiceAddress?.searchingAddress) {
      setTitle('Edit Invoice Address')
    } else {
      setTitle('Add Invoice Address')
    }

    setSaveFn(() => (data: any) => setInvoiceAddress(data))
    setAddressDetails({
      ...invoiceAddress
    })
    open()
  }

  useEffect(() => {
    if (isSameAddress && values.deliveryMethod === 'delivery' && userType !== 'linked') {
      setFieldValue(`${invoiceAddressAttentionTo}`, values.orderShippingAddress?.attentionTo)

      setFieldValue(`${invoiceAddressStreetAddress}`, values.orderShippingAddress?.streetAddress)

      setFieldValue(`${invoiceAddressSuburb}`, values.orderShippingAddress?.suburb)

      setFieldValue(`${invoiceAddressCity}`, values.orderShippingAddress?.city)

      setFieldValue(`${invoiceAddressPostcode}`, values.orderShippingAddress?.postCode)

      setFieldValue(
        `${invoiceAddressSearchingAddress}`,
        getSearchingAddress(
          values.orderShippingAddress?.streetAddress,
          values.orderShippingAddress?.suburb,
          values.orderShippingAddress?.city,
          values.orderShippingAddress?.postCode
        )
      )

      setInvoiceAddress({
        searchingAddress: getSearchingAddress(
          values.orderShippingAddress?.streetAddress,
          values.orderShippingAddress?.suburb,
          values.orderShippingAddress?.city,
          values.orderShippingAddress?.postCode
        ),
        attentionTo: values.orderShippingAddress?.attentionTo,
        streetAddress: values.orderShippingAddress?.streetAddress,
        suburb: values.orderShippingAddress?.suburb,
        city: values.orderShippingAddress?.city,
        postcode: values.orderShippingAddress?.postCode
      })
    }
  }, [isSameAddress, values.deliveryMethod, values.orderShippingAddress])

  useEffect(() => {
    if (!isSameAddress && userType !== 'linked') {
      setFieldValue(`${invoiceAddressAttentionTo}`, invoiceAddress.attentionTo)

      setFieldValue(`${invoiceAddressStreetAddress}`, invoiceAddress.streetAddress)

      setFieldValue(`${invoiceAddressSuburb}`, invoiceAddress.suburb)

      setFieldValue(`${invoiceAddressCity}`, invoiceAddress.city)

      setFieldValue(`${invoiceAddressPostcode}`, invoiceAddress.postcode)

      setFieldValue(
        `${invoiceAddressSearchingAddress}`,
        getSearchingAddress(
          invoiceAddress.streetAddress,
          invoiceAddress.suburb,
          invoiceAddress.city,
          invoiceAddress.postcode
        )
      )
    }
  }, [invoiceAddress, isSameAddress])

  useEffect(() => {
    setIsSameAddress(values.deliveryMethod !== 'pickup')
  }, [values.deliveryMethod])

  return (
    <div
      id={'invoice-address-section'}
      className={CN('flex flex-col gap-[20px] p-[20px] bg-white rounded', className)}>
      <span
        className='text-h3 tracking-[-0.48px] text-N-800 font-heading font-600'
        data-component-id={`${componentId}-header`}>
        {values.deliveryMethod === 'pickup' && isNoStoresToPickup ? '5' : '6'}. Invoice Address
      </span>

      {userType === 'linked' && initInvoiceAddress && (
        <AddressCard
          componentId={`${componentId}`}
          data={initInvoiceAddress}
          className='w-full md:!w-7/12 lg:!w-1/2'
        />
      )}

      {userType !== 'linked' && (
        <div className='flex flex-col gap-5'>
          {values.deliveryMethod === 'delivery' && (
            <CheckBox
              checked={isSameAddress}
              checkBoxInnerWrapperClassName='!left-[-16px]'
              componentId='worksafe-form-same-address'
              id='checkout-invoice-block-same-as-shipping-address'
              view='card'
              label='Same as Shipping Address'
              onChange={() => setIsSameAddress(prev => !prev)}
            />
          )}

          {!isSameAddress && (
            <div className='flex flex-col gap-[20px] w-full'>
              <AddressBlock
                city={invoiceAddress?.city}
                field='customerInvoiceAddress.searchingAddress'
                onSearchFieldClick={handleInvoiceAddressClick}
                placeholder='Start typing an address, e.g. 123 main'
                postcode={invoiceAddress?.postcode}
                searchingAddress={invoiceAddress?.searchingAddress}
                streetAddress={invoiceAddress?.streetAddress}
                suburb={invoiceAddress?.suburb}
                hasError={
                  !!(
                    invoiceAddress?.searchingAddress === '' &&
                    touched?.invoiceAddress?.searchingAddress
                  )
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default InvoiceAddressBlock
