import React, { FC, useEffect, useState } from 'react'
import { Button, Drawer, DropDownModal } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import useWindowDimensions from 'lib/hooks/useWindowDimensions'

import { MyAccountDropdownBody } from './MyAccountDropdownBody'

export interface IMyAccountDropdownProps {
  className?: string
  logout?: any
}

export const MyAccountDropdown: FC<IMyAccountDropdownProps> = ({
  className,
  logout
}: IMyAccountDropdownProps) => {
  const MyAccountDropdownClasses = CN(`my-account-dropdown `, className)

  const { width } = useWindowDimensions()

  const { myAccountModal } = useModalContext()

  const { user, session } = useAppContext()

  //const isLined = true

  const [isLinked, setIsLinked] = useState(false)

  useEffect(() => {
    if (
      user?.data?.emailAddress &&
      (user.data.emailAddress === 'jhone@mailinator.com' ||
        user.data.emailAddress === 'wilson@mailinator.com' ||
        user.data.emailAddress === 'franky@mailinator.com')
    ) {
      setIsLinked(true)
    } else {
      if (
        user?.data?.accountCode === 'WEBCARD 001' ||
        user?.data?.accountCode === undefined ||
        user?.data?.accountCode === null
      ) {
        setIsLinked(false)
      } else {
        setIsLinked(true)
      }
    }
  }, [user])

  /**
   * jhone@mailinator.com
   * wilson@mailinator.com
   * franky@mailinator.com
   */

  return (
    <div className={MyAccountDropdownClasses}>
      {width >= 744 ? (
        <DropDownModal
          bodyClassName='w-[559px] !overflow-auto max-h-[calc(100vh-80px)]'
          disabled={false}
          isOpen={myAccountModal.isOpen}
          onOutsideClick={myAccountModal.close}
          toggle={{
            componentId: 'header-my-account',
            appearance: 'primary-deep-blue',
            iconAfter: 'nzsbi-user-default',
            className:
              'h-[40px] w-[40px] hover:!bg-B-600 flex justify-center focus:ring-offset-0 focus:ring-0 focus:shadow-none',
            iconOnly: true,
            onClick: () => (myAccountModal.isOpen ? myAccountModal.close() : myAccountModal.open())
          }}
          toggleSize='sm'
          position='left'>
          <MyAccountDropdownBody isLinkedCRAccount={isLinked} logout={logout} />
        </DropDownModal>
      ) : (
        <>
          {/* show modal button  */}
          <Button
            componentId='header-my-account'
            onClick={() => {
              myAccountModal.set(!myAccountModal.isOpen)
            }}
            appearance='primary-deep-blue'
            iconAfter='nzsbi-user-default'
          />

          {/* modal component  */}
          <Drawer
            show={myAccountModal.isOpen}
            headerTitle=''
            primaryButtonTitle=''
            animationType='slideFromRight'
            secondaryButtonTitle={
              session?.isPunchOut && session?.isIframe ? undefined : (
                <span className='uppercase text-R-500'>Sign Out</span>
              )
            }
            secondaryButtonProps={
              session?.isPunchOut && session?.isIframe
                ? undefined
                : {
                    onClick: () => {
                      logout()
                      myAccountModal.set(!myAccountModal.isOpen)
                    }
                  }
            }
            buttonWidthFull
            size='xs'
            headerClass='border-none'
            /** Overlay click function */
            onOverlayClick={() => {
              myAccountModal.set(!myAccountModal.isOpen)
            }}
            headerButtonProps={{
              onClick: () => {
                myAccountModal.set(!myAccountModal.isOpen)
              }
            }}>
            <div className='pb-[24px] px-[24px] w-full relative'>
              <MyAccountDropdownBody isLinkedCRAccount={isLinked} logout={logout} />
            </div>
          </Drawer>
        </>
      )}
    </div>
  )
}

MyAccountDropdown.defaultProps = {
  className: undefined
}

export default MyAccountDropdown
