import React, { FC } from 'react'

export interface MobileMenuSubHeaderProps {
  className?: string
  subHeaderSlug?: string
  onBackClick?: () => void
  subHeaderTitle?: string
}

export const MobileMenuSubHeader: FC<MobileMenuSubHeaderProps> = ({
  className,
  subHeaderSlug,
  onBackClick,
  subHeaderTitle,
  ...restProps
}: MobileMenuSubHeaderProps) => {
  return (
    <div
      data-component-id='main-menu-side-pane-drawer-sub-header'
      className='flex gap-x-3 pt-[6px] justify-start items-center'
      {...restProps}>
      {/**Sub Header Back Button */}
      <i
        tabIndex={0}
        role='button'
        data-component-id='main-menu-side-pane-drawer-sub-header-back-btn'
        className='nzsbi-arrow-left text-[20px] text-N-700'
        onClick={onBackClick}
        onKeyDown={e => {
          if (onBackClick && e.key === 'Enter') {
            onBackClick()
          }
        }}
      />

      {/** Sub Header Title */}
      {!subHeaderSlug && (
        <span
          data-component-id='main-menu-side-pane-drawer-sub-header-title'
          className='font-700 text-base text-N-800'>
          {subHeaderTitle}
        </span>
      )}
      {subHeaderSlug && (
        <a
          data-component-id='main-menu-side-pane-drawer-sub-header-title'
          className='font-700 text-base text-N-800'
          href={subHeaderSlug}>
          {subHeaderTitle}
        </a>
      )}
    </div>
  )
}

export default MobileMenuSubHeader
