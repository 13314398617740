import { useQuery } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IOrderHistoryReq {
  emailAddress?: string
  accountNumber?: string
  deliverySequence?: string
  searchFilter?: {
    field: string
    value: string
  }
  orderDateRange?: {
    startDate: string
    endDate: string
  }
  orderType?: Array<number>
  orderAmount?: {
    fromAmount: number
    toAmount: number
  }
  orderStatus?: Array<number>
  pageIndex: number
  pageSize: number
  sortFilter?: {
    field: string
    type: string
  }
  onlyMyOrders?: boolean
}

export interface IOrder {
  orderNumber: string
  webOrderId: number
  purchaseOrderReference: number
  date: string
  numberOfItems: number
  createdDate: string
  orderType: number
  fullyDispatched: number
  outstanding: number
  notDispatched: number
  onPick: number
  allocated: number
  shippingCharge: number
  shippingChargeWithGst: number
  gst: number
  subTotal: number
  totalPriceWithGst: number
  traceLink: string
  status: number
  customerReference: string
  amount: number
}

export interface IOrderHistoryData {
  orders: Array<IOrder>
  resultCount: number
  pageIndex: number
  pageSize: number
  totalPages: number
}

export interface IOrderHistoryRes {
  data: IOrderHistoryData
  messageDescription: null | string
  messageTitle: string
  messageType: string
}

/**
 * Fetch order history
 * @param req Request object for fetching order history
 * @param context This is required for SSR only, otherwise the token is not attached
 * @returns IOrderHistoryRes
 */
export const fetchOrderHistory = async (req: any, context?: any) => {
  const { data } = await httpPOST({
    url: '/order/gethistory',
    body: req,
    context
  })

  return data?.data as IOrderHistoryRes
}

export const useGetOrderHistory = (req: IOrderHistoryReq, initialData: any) => {
  /* Use Query */
  return useQuery({
    queryKey: ['GET_ORDER_HISTORY', req],
    queryFn: () => fetchOrderHistory(req),
    initialData: initialData
  })
}

export default useGetOrderHistory
