import React, { FC, ReactNode, useEffect, useState } from 'react'
import CN from 'classnames'

interface FocusableSidePaneProps {
  children?: ReactNode | string | number
  className?: string
  defaultSelectedItemId: number
  isActive?: boolean
  focusableSidePanelList?: {
    id: number
    itemName: string
  }[]
  sectionRefs?: { [key: string]: React.RefObject<HTMLDivElement> }
  [x: string]: any
}

export const FocusableSidePane: FC<FocusableSidePaneProps> = ({
  className,
  contentClassName,
  defaultSelectedItemId,
  isActive,
  sectionRefs,
  focusableSidePanelList,
  ...restProps
}: FocusableSidePaneProps) => {
  const FocusableSidePanelClasses = CN(
    `focusable-side-panel h-full w-full hidden lg:flex`,
    className
  )
  const [activeItem, setActiveItem] = useState<number>(defaultSelectedItemId)

  useEffect(() => {
    setActiveItem(defaultSelectedItemId)
  }, [defaultSelectedItemId, isActive])

  const scrollToElement = (element: HTMLElement | null) => {
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className={FocusableSidePanelClasses} {...restProps}>
      <div
        className={CN(
          'focusable-side-panel-button flex flex-col border-l-Gray-200 text-left h-fit gap-[8px]'
        )}>
        {focusableSidePanelList?.map((item, id: number) => (
          <div
            tabIndex={0}
            role='button'
            key={item?.id}
            className={CN('flex hover:bg-N-25 py-[8px] px-[16px] cursor-pointer', {
              'text-sm text-B-500 font-500 cursor-default': activeItem === item?.id,
              'text-sm text-N-700 font-400 text-small hover:text-Gray-600': activeItem !== item?.id
            })}
            onClick={() => {
              setActiveItem(item?.id)
              const sectionRef = sectionRefs?.[item?.id]
              if (sectionRef?.current) {
                scrollToElement(sectionRef.current)
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setActiveItem(item?.id)
                const sectionRef = sectionRefs?.[item?.id]
                if (sectionRef?.current) {
                  scrollToElement(sectionRef.current)
                }
              }
            }}>
            <div key={item?.id ?? id} className='text-left'>
              {item?.itemName}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FocusableSidePane
