import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { DOTS, usePagination } from 'lib/hooks/usePagination'

export interface IPaginationProps {
  className?: string
  isOnlyShowPageButtonInDesktop?: boolean
  pageSizes?: number
  totalPages: number
  pageIndex: number
  resultsCount?: number
  siblingCount?: number
  onPageChange?: any
}

export const Pagination: FC<IPaginationProps> = ({
  className,
  isOnlyShowPageButtonInDesktop = false,
  pageIndex,
  totalPages,
  pageSizes,
  resultsCount,
  siblingCount,
  onPageChange,
  ...restProps
}: IPaginationProps) => {
  const PaginationClasses = CN(`pagination flex gap-[4px] sm:gap-[8px] items-center`, className)

  const paginationRange: any = usePagination({
    totalPages,
    pageIndex,
    resultsCount,
    siblingCount,
    pageSizes
  })

  // If there are less than 1 times in pagination range we shall not render the component
  if (pageIndex === 0 || totalPages < 1) {
    return null
  }

  const onNext = () => {
    onPageChange(pageIndex + 1)
  }

  const onPrevious = () => {
    onPageChange(pageIndex - 1)
  }

  const lastPage = paginationRange[paginationRange.length - 1]

  return (
    <div className={PaginationClasses} {...restProps}>
      {/* Left navigation arrow */}
      <Button
        componentId='pagination-previous'
        appearance='secondary-gray'
        disabled={pageIndex === 1}
        className={CN('focus:ring-offset-0 focus:ring-0 w-[40px] !px-[8px]', {
          '!bg-white': pageIndex === 1
        })}
        iconOnly
        onClick={onPrevious}
        iconBefore='nzsbi-chevron-left'
      />
      <div
        className={CN('gap-[8px] hidden', {
          'md:!flex': !isOnlyShowPageButtonInDesktop,
          'lg:!flex': isOnlyShowPageButtonInDesktop
        })}>
        {paginationRange.map((numberItem: any, idx: number) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (numberItem === DOTS) {
            return (
              <Button
                componentId='pagination-dots'
                key={numberItem ?? idx}
                appearance='secondary-gray'
                className='focus:ring-offset-0 focus:ring-0 w-[40px] !px-[8px]'>
                ...
              </Button>
            )
          }

          // Render our Page Pills
          return (
            <Button
              componentId={`pagination-pageNumber-${idx}`}
              appearance={numberItem === pageIndex ? 'primary-deep-blue' : 'secondary-gray'}
              key={numberItem ?? idx}
              className={CN('focus:ring-offset-0 focus:ring-0 w-[40px]', {
                '!w-[48px] !px-[8px]': numberItem > 9 && numberItem < 100,
                '!w-[56px]': numberItem > 99
              })}
              onClick={() => onPageChange(numberItem)}>
              {numberItem}
            </Button>
          )
        })}
      </div>
      <span
        className={CN('lowercasepx-[20px]', {
          'md:!hidden ': !isOnlyShowPageButtonInDesktop,
          'lg:!hidden ': isOnlyShowPageButtonInDesktop
        })}>
        {pageIndex} of {totalPages}
      </span>

      {/*  Right Navigation arrow */}
      <Button
        componentId='pagination-next'
        appearance='secondary-gray'
        disabled={pageIndex === lastPage}
        className={CN('focus:ring-offset-0 focus:ring-0 w-[40px] !px-[8px]', {
          '!bg-white': pageIndex === lastPage
        })}
        iconOnly
        onClick={onNext}
        iconBefore='nzsbi-chevron-right'
      />
    </div>
  )
}

Pagination.defaultProps = {
  pageSizes: 5,
  totalPages: 10,
  pageIndex: 1,
  resultsCount: 100,
  siblingCount: 2,
  onPageChange: () => {}
}

export default Pagination
