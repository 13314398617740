/* eslint-disable import/no-extraneous-dependencies */
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperCore } from 'swiper/types'
export interface IHeroProps {
  className?: string
  heroSlides: Array<{
    Header: string
    SubHeader: string
    DesktopOverlay: 'None' | 'Very_Light' | 'Light' | 'Medium' | 'Dark'
    MobileOverlay: 'None' | 'Very_Light' | 'Light' | 'Medium' | 'Dark'
    ActionButton: {
      ButtonText: string
      Url: string
      IconBefore: string
      IconAfter: string
    }
    MobileImage: {
      data: {
        id: string
        attributes: {
          name: string
          alternativeText: string | null
          url: string
        }
      }
    }
    DesktopImage: {
      data: {
        id: string
        attributes: {
          name: string
          alternativeText: string | null
          url: string
        }
      }
    }
  }>
}

export const Hero: FC<IHeroProps> = ({ className, heroSlides, ...restProps }: IHeroProps) => {
  const router = useRouter()
  const HeroClasses = CN(`hero flex items-center`, className)

  const swiperRef = useRef<SwiperCore>()

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0)

  const onButtonClick = (url: string) => {
    const urlObj = new URL(url)
    if (window.location?.origin !== urlObj?.origin) {
      window.open(url, '_blank')
    } else {
      router.push(url)
    }
  }

  const onPaginationButtonClick = (index: number) => {
    swiperRef.current?.slideToLoop(index)
  }

  useEffect(() => {
    if (heroSlides?.length > 1) {
      swiperRef.current?.slideToLoop(0)
    }
  }, [heroSlides])

  return (
    <section className={HeroClasses} {...restProps}>
      <div className='relative w-full h-full flex items-center justify-center md:bg-B-500'>
        {/* Customized pagination */}
        {heroSlides?.length > 1 && (
          <div className='absolute bottom-[12px] z-10 gap-[12px] hidden md:flex'>
            {heroSlides?.map((slide, index) => (
              <button
                id={`button-${index}`}
                aria-label={`indicator-button-${index}`}
                onClick={() => onPaginationButtonClick(index)}
                key={`${index}-slide`}
                className={CN('w-[8px] h-[8px] rounded-xl bg-white bottom-0 z-40', {
                  'ring-1 ring-white !bg-B-500': activeSlideIndex === index
                })}
              />
            ))}
          </div>
        )}
        {/* Hero Left Pagination button */}
        {heroSlides?.length > 1 && (
          <button
            id='prev-button'
            aria-label='prev-button'
            className='items-center justify-center hidden md:flex absolute top-[50%] left-[40px] mt-[-24px] lg:!mt-[-40px] w-[24px] h-[24px] lg:!w-[48px] lg:!h-[48px] bg-B-25 z-50 border border-N-100 rounded'
            onClick={() => swiperRef.current?.slidePrev()}>
            <i className='ri-arrow-left-s-line text-N-700 text-xs lg:!text-[24px]' />
          </button>
        )}

        <Swiper
          loop={heroSlides?.length > 3}
          centeredSlides
          slidesPerView={'auto'}
          className='w-full md:h-[220px] lg:h-[420px] !hidden md:!block '
          onBeforeInit={swiper => {
            swiperRef.current = swiper
          }}
          onRealIndexChange={val => {
            if (activeSlideIndex === undefined) {
              setActiveSlideIndex(0)
            } else {
              setActiveSlideIndex(val.realIndex)
            }
          }}>
          {heroSlides?.map((promo, index) => (
            <SwiperSlide
              key={promo?.MobileImage?.data?.id ?? index}
              className={CN('!px-0 flex h-[420px] max-w-[620px] lg:max-w-[1200px]', {
                'pl-[12px]': index === 0 && heroSlides?.length > 1
              })}>
              {({ isActive }) => (
                <>
                  {!isActive && <div className='absolute w-full h-full bg-N-800 opacity-50 z-20' />}

                  {isActive && (
                    <div
                      className={CN(
                        'absolute w-full h-full bg-gradient-to-r from-black z-10 from-50%',
                        {
                          'opacity-0': promo?.DesktopOverlay === 'None',
                          'opacity-10': promo?.DesktopOverlay === 'Very_Light',
                          'opacity-30': promo?.DesktopOverlay === 'Light',
                          'opacity-50': promo?.DesktopOverlay === 'Medium',
                          'opacity-70': promo?.DesktopOverlay === 'Dark'
                        }
                      )}
                    />
                  )}

                  <div className='z-10 pl-[20px] py-[20px] lg:!pl-[40px] lg:!py-[40px] relative h-full'>
                    {promo?.Header && (
                      <div className='text-white uppercase z-20 font-display text-display-h1-m lg:text-[65px] lg:leading-[76px] w-[240px] lg:w-[495px]'>
                        {promo?.Header}
                      </div>
                    )}
                    {promo?.SubHeader && (
                      <div className='text-white mt-[12px] text-code lg:text-display-body z-20'>
                        {promo?.SubHeader}
                      </div>
                    )}
                    {promo?.ActionButton && (
                      <>
                        <Button
                          onClick={() => {
                            onButtonClick(promo?.ActionButton?.Url)
                          }}
                          size='lg'
                          appearance='primary-orange'
                          iconAfter={promo?.ActionButton?.IconAfter}
                          iconBefore={promo?.ActionButton?.IconBefore}
                          className='absolute bottom-[40px] hidden lg:!block z-20'>
                          {promo?.ActionButton?.ButtonText}
                        </Button>
                        <Button
                          onClick={() => {
                            onButtonClick(promo?.ActionButton?.Url)
                          }}
                          size='xs'
                          appearance='primary-orange'
                          iconAfter={promo?.ActionButton?.IconAfter}
                          iconBefore={promo?.ActionButton?.IconBefore}
                          className='absolute bottom-[20px] md:mt-[16px] lg:mt-[32px] lg:!hidden'>
                          {promo?.ActionButton?.ButtonText}
                        </Button>
                      </>
                    )}
                  </div>
                  <Image
                    className='lg:max-w-[1200px]'
                    src={
                      promo?.DesktopImage?.data?.attributes?.url === null
                        ? 'https://via.placeholder.com/72x72/afd8e6/969696?text=No+Image'
                        : promo?.DesktopImage?.data?.attributes?.url
                    }
                    alt={promo?.DesktopImage?.data?.attributes?.alternativeText || 'Hero Slide'}
                    fill
                    style={{ objectFit: 'cover' }}
                    placeholder='blur'
                    blurDataURL={
                      promo?.DesktopImage?.data?.attributes?.url ||
                      'https://via.placeholder.com/72x72/afd8e6/969696'
                    }
                    loading='eager'
                    priority={true}
                  />
                </>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        {heroSlides?.length > 1 && (
          <button
            id='next-button'
            aria-label='next-button'
            className='hidden md:flex justify-center items-center absolute mt-[-24px] lg:!mt-[-40px] top-[50%] right-[40px] w-[24px] h-[24px] lg:!w-[48px] lg:!h-[48px] bg-B-25 z-40 border-1 border-N-100 rounded'
            onClick={() => swiperRef.current?.slideNext()}>
            <i className='ri-arrow-right-s-line text-N-700  text-xs lg:!text-[24px]' />
          </button>
        )}

        <Swiper
          spaceBetween={14}
          slidesPerView={'auto'}
          onRealIndexChange={val => {
            setActiveSlideIndex(val.realIndex)
          }}
          className='w-full h-full block md:hidden mt-[40px] mb-[40px]'>
          {heroSlides?.map((promo, index) => (
            <SwiperSlide
              key={promo?.MobileImage?.data?.id ?? index}
              className={CN('max-w-[288px] rounded flex', {
                'ml-[12px]': index === 0
              })}>
              <div
                className={CN(
                  'absolute w-full h-full bg-red bg-gradient-to-r from-black z-10 from-50%',
                  {
                    'opacity-0': promo?.MobileOverlay === 'None',
                    'opacity-10': promo?.MobileOverlay === 'Very_Light',
                    'opacity-30': promo?.MobileOverlay === 'Light',
                    'opacity-50': promo?.MobileOverlay === 'Medium',
                    'opacity-70': promo?.MobileOverlay === 'Dark'
                  }
                )}
              />
              <div className='z-10 ml-[24px] max-w-[200px] lg:max-w-[495px] h-[352px] relative'>
                {promo?.Header && (
                  <div className='text-white z-10 font-display text-display-h2 md:text-display-h1 mt-[22px] '>
                    {promo?.Header}
                  </div>
                )}
                {promo?.SubHeader && (
                  <div className='text-white mt-[12px] text-display-body'>{promo?.SubHeader}</div>
                )}

                {promo?.ActionButton && (
                  <Button
                    onClick={() => {
                      onButtonClick(promo?.ActionButton?.Url)
                    }}
                    size='xs'
                    appearance='primary-orange'
                    iconAfter={promo?.ActionButton?.IconAfter}
                    iconBefore={promo?.ActionButton?.IconBefore}
                    className='absolute bottom-[24px]'>
                    {promo?.ActionButton?.ButtonText}
                  </Button>
                )}
              </div>
              <Image
                className='rounded'
                src={
                  promo?.MobileImage?.data?.attributes?.url === null
                    ? 'https://via.placeholder.com/72x72/afd8e6/969696?text=No+Image'
                    : promo?.MobileImage?.data?.attributes?.url
                }
                alt={promo?.MobileImage?.data?.attributes?.alternativeText || 'Hero Slide'}
                fill
                style={{ objectFit: 'cover' }}
                placeholder='blur'
                blurDataURL={
                  promo?.MobileImage?.data?.attributes?.url ||
                  'https://via.placeholder.com/72x72/afd8e6/969696'
                }
                decoding='async'
                loading='eager'
                priority={true}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}

export default Hero
