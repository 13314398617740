import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IAllCategory } from 'lib/actions'

import { OctagonWrapper } from 'components/atoms'

export interface IAllProductCategoryCardProps {
  cardTitle: string
  childCategories?: IAllCategory | null
  className?: string
  icon: string
  urlSlug: string
}

export const AllProductCategoryCard: FC<IAllProductCategoryCardProps> = ({
  cardTitle,
  childCategories,
  className,
  icon,
  urlSlug,
  ...restProps
}: IAllProductCategoryCardProps) => {
  const MyAccountTitleCardClasses = CN(
    `bg-white border-[1px] border-N-100 rounded px-[20px] pb-[24px] w-full h-full cursor-pointer`,
    className
  )

  const router = useRouter()

  return (
    <div
      data-component-id='all-product-category-card'
      className={MyAccountTitleCardClasses}
      {...restProps}>
      {icon && (
        <span>
          {/* Category Pillar tile icon section */}
          <OctagonWrapper
            componentId='all-product-category-card-icon'
            icon={icon}
            className='absolute flex items-center justify-center mt-[-24px]'
          />
        </span>
      )}

      <div className='flex flex-col h-full'>
        {/** Category pillar tile header section */}
        <div
          className='text-h3 font-semibold text-N-800 mb-[16px] mt-[60px] capitalize'
          data-component-id={`all-product-category-card-${cardTitle}`}>
          <a href={`/category/${urlSlug}`}>{cardTitle}</a>
        </div>

        {/** Category Pillar tile section */}
        <div
          className='text-base font-normal text-N-700 flex flex-col gap-y-3'
          data-component-id='all-product-category-card-child-categories'>
          {Array?.isArray(childCategories) &&
            childCategories?.map((subCategory: any, subIndex: number) => (
              <Link
                href={`/category/${urlSlug}/${subCategory?.urlSlug}`}
                data-component-id={`all-product-category-card-child-categories-${subIndex}`}
                className={CN(`hover:text-O-500`)}
                key={subCategory?.name ?? subIndex}>
                {subCategory?.name}
              </Link>
            ))}
        </div>

        {/** Category Pillar tile see all btn section */}
        <div className='w-full h-full flex items-end justify-end'>
          <Button
            className='w-fit'
            isBlock={false}
            size='md'
            isRingOffset={false}
            iconAfter='nzsbi-chevron-right'
            onClick={() => {
              router.push(`/category/${urlSlug}`)
            }}
            componentId={`all-product-category-card-see-all-btn`}
            appearance='link'>
            See All
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AllProductCategoryCard
