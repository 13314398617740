import React, { FC } from 'react'
import CN from 'classnames'
import { IProductRelatedProduct } from 'lib/actions'

import { ProductAccessoryCard } from '../ProductAccessoryCard'

export interface IAccessoriesListProps {
  className?: string
  productsData?: IProductRelatedProduct[]
}

export const AccessoriesList: FC<IAccessoriesListProps> = ({
  className,
  productsData,
  ...restProps
}: IAccessoriesListProps) => {
  const AccessoriesListClasses = CN(`accessories-list`, className)

  return (
    <div className={AccessoriesListClasses} {...restProps}>
      <div className='flex flex-col w-full gap-[12px] mt-[16px]'>
        {productsData?.map((item, index) => (
          <ProductAccessoryCard productData={item} key={`${item?.id ?? index}-product-cards`} />
        ))}
      </div>
    </div>
  )
}

export default AccessoriesList
