import { useMutation } from '@tanstack/react-query'
import { httpPatch } from 'lib/http'

export interface IReInitializeCartByOrderReq {
  cartId: string
  orderNumber: string
}

export interface IReInitializeCartByOrderRes {
  data: any
  messageDescription: null | string
  messageTitle: string
  messageType: string
}

export const reInitializeCartByOrder = async (req: any, context?: any) => {
  const { data } = await httpPatch({
    url: '/cart/reinitailize-cart-by-order',
    body: req,
    context
  })
  return data?.data as IReInitializeCartByOrderRes
}

export const useReInitializeCartByOrder = (req: IReInitializeCartByOrderReq) => {
  return useMutation({
    mutationFn: () => reInitializeCartByOrder(req)
  })
}

export default useReInitializeCartByOrder
