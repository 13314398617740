import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { priceFormat } from '@nzsb/shopnx-ui'
import { useFormikContext } from 'formik'
import { IFormValues } from 'pages/checkout'
import { IGetCartDetails } from 'lib/actions'

import { NextImage } from 'components/atoms'

import { CheckOutProdItemCard } from '../CheckOutProdItemCard'

export interface CheckOutOrderSummaryProps {
  cartDetails: IGetCartDetails
  showGstPricePrimary: boolean
  showShippingCharges: boolean
}

/** Render Image with Next Image component */
const RenderImage = (productData: any) => {
  return (
    <div className='relative h-[32px] w-[32px]'>
      <NextImage
        imgKey={`${productData?.sku}-${productData?.id}`}
        width={32}
        height={32}
        imageUrl={productData?.image?.url}
        alt={productData?.image?.alt || productData?.title}
      />
    </div>
  )
}

export const CheckOutOrderSummary: FC<CheckOutOrderSummaryProps> = ({
  cartDetails,
  showGstPricePrimary,
  showShippingCharges
}) => {
  const router = useRouter()

  const { values } = useFormikContext<IFormValues>()

  return (
    <div className='flex flex-col pt-[16px] w-full border border-N-100 rounded bg-white'>
      <div className='flex flex-col pl-[16px] pr-[8px] gap-5'>
        <span className='font-sans text-xs font-700 text-N-800 uppercase'>
          ORDER SUMMARY ({cartDetails?.cartItems?.length} ITEM
          {cartDetails?.cartItems && cartDetails?.cartItems?.length > 1 && `S`})
        </span>
        <div className='flex flex-col w-full h-fit max-h-[356px] pr-[8px] gap-5 overflow-auto overflow-x-hidden scrollbar'>
          {cartDetails?.cartItems?.map((item, index) => (
            <CheckOutProdItemCard
              key={item?.id}
              componentId={`quick-cart-item-${index}`}
              quantity={item?.quantity}
              uom={item?.uom}
              lozengeClass='!z-10'
              productFlag={item?.productFlag}
              isShowCatalogIcon={item?.isCatalogue}
              isShowGstPricePrimary={showGstPricePrimary}
              title={item?.name}
              currentPrice={item?.totalPrice}
              withGst={item?.totalPriceWithGST}
              eachPrice={item?.price}
              eachPriceWithGst={item?.priceWithGst}
              nextImage={RenderImage(item)}
              sku={item?.sku}
              onTitleClick={() => {
                router.push(`/product/${item?.urlSlug}`)
              }}
            />
          ))}
        </div>
      </div>
      <hr className='m-4 text-N-100' />
      <div className='flex flex-col gap-2 px-4'>
        {/* Sub Total */}
        <div className='flex justify-between'>
          <span className='font-sans text-sm font-400 text-N-400'>Sub-total</span>
          {cartDetails?.grossAmount && (
            <span className='font-sans text-sm font-500 text-N-700'>
              ${priceFormat(cartDetails?.grossAmount)}
            </span>
          )}
        </div>

        {/* Shipping Charges */}
        {showShippingCharges && (
          <div className='flex justify-between'>
            <span className='font-sans text-sm font-400 text-N-400'>Shipping-charges</span>
            {cartDetails?.shippingCharge !== undefined && (
              <span className='font-sans text-sm font-500 text-N-700'>
                {values?.deliveryMethod === 'pickup' || cartDetails?.shippingCharge === 0
                  ? 'Free'
                  : '$' + priceFormat(cartDetails?.shippingCharge)}
              </span>
            )}
          </div>
        )}

        {/* GST */}
        <div className='flex justify-between'>
          <span className='font-sans text-sm font-400 text-N-400'>GST</span>
          {cartDetails?.gstAmount && (
            <span className='font-sans text-sm font-500 text-N-700'>
              $
              {values?.deliveryMethod === 'delivery'
                ? priceFormat(cartDetails?.gstAmount)
                : priceFormat(
                    cartDetails?.gstAmount -
                      (Number(cartDetails?.shippingChargeWithGst) -
                        Number(cartDetails?.shippingCharge))
                  )}
            </span>
          )}
        </div>
      </div>

      {/* Order Total */}
      <div className='flex items-center justify-between bg-B-25 p-3 mt-4 font-sans text-sm font-700 text-N-700'>
        <span className='w-[73px]'>
          {showGstPricePrimary ? 'Order Total (Incl. GST)' : 'Order Total'}
        </span>
        {cartDetails?.totalAmount && (
          <span>
            {showShippingCharges && values?.deliveryMethod === 'delivery'
              ? priceFormat(
                  (cartDetails?.grossAmount ?? 0) +
                    (cartDetails?.shippingCharge ?? 0) +
                    (cartDetails?.gstAmount ?? 0)
                )
              : priceFormat(
                  (cartDetails?.grossAmount ?? 0) +
                    (cartDetails?.gstAmount ?? 0) -
                    (Number(cartDetails?.shippingChargeWithGst) -
                      Number(cartDetails?.shippingCharge))
                )}
          </span>
        )}
      </div>
    </div>
  )
}

export default CheckOutOrderSummary
