import { NextRouter } from 'next/router'

export const changeRouter = (
  router: NextRouter,
  key?: string | string[],
  value?: string | string[],
  removeKeys?: string[]
) => {
  const url = new URL(window.location.href)

  if (Array.isArray(key) && Array.isArray(value)) {
    key.forEach((k, index) => {
      if (value[index] !== undefined) {
        url.searchParams.set(k, value[index])
      }
    })
  } else if (typeof key === 'string' && typeof value === 'string') {
    url.searchParams.set(key, value)
  }

  removeKeys?.forEach(removeKey => url.searchParams.delete(removeKey))
  router.push(url.toString(), undefined, { shallow: true })
}

export default changeRouter
