import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IRemoveCartItemResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: any
}

export interface IRemoveCartItemReq {
  productId: string
  variantId: number
  cartId?: string
}

export const removeCartItem = async (req: IRemoveCartItemReq) => {
  const data = await httpPOST({
    url: 'cart/removefromcart',
    body: req
  })

  return data?.data as IRemoveCartItemResponse
}

export const useRemoveCartItem = () => {
  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: removeCartItem
  })

  return { removeCartItemResponse: data, removeCartItemAsync: mutateAsync, ...restOptions }
}
