/* eslint-disable import/no-extraneous-dependencies */
import Image from 'next/image'
import React, { FC, useEffect, useState } from 'react'
import { Button, CheckBox, Modal, ModalBody, Select, Spinner } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import GoogleMapReact from 'google-map-react'
import greyMarker from 'public/images/svgs/greyLocationPin.svg'
import mapMarker from 'public/images/svgs/locationMarker.svg'
import {
  IBranchInfo,
  useGetProductPickupDetails
} from 'lib/actions/products/getProductPickupDetails'
import { useModalContext } from 'lib/contexts/modal-context'

import { NextImage } from 'components/atoms'

export interface IPickupModalProps {
  className?: string
}

interface ISelectedBranch extends IBranchInfo {
  index: number
}

export const PickupModal: FC<IPickupModalProps> = ({
  className,
  ...restProps
}: IPickupModalProps) => {
  const PickupModalClasses = CN(`stock-location`, className)

  const { pickUpModal } = useModalContext()
  const { isOpen, close, name, sku, id, image } = pickUpModal

  /** For the show more action in the description section for the mobile and tab view */
  const [showMore, setShowMore] = useState(false)
  /** For the filter of filtering the locations where stock only exist */
  const [showOnlyStockAvailable, setShowOnlyStockAvailable] = useState(true)
  const [selectedBranch, setSelectedBranch] = useState<ISelectedBranch>()

  // map references
  const mapRef = React.useRef<any>(null)
  const mapsRef = React.useRef<any>(null)
  const [isLoadingMaps, setIsLoadingMaps] = useState(true)

  const defaultProps = {
    center: {
      lat: -41.2851,
      lng: 174.776
    },
    zoom: 8
  }

  const { data: pickupDetails, isLoading: isPickUpDetailsLoading } = useGetProductPickupDetails(id)
  const [branches, setBranches] = useState<IBranchInfo[]>([])
  const [filteredBranches, setFilteredBranches] = useState<IBranchInfo[]>([])

  const [selectedRegion, setSelectedRegion] = useState<{ value: string; label: string }>()

  useEffect(() => {
    if (pickupDetails) {
      const { branches: branchesAPIdata } = pickupDetails
      const branchesData = branchesAPIdata?.map(item => [...item.branches]).flat() || []
      setBranches(branchesData)
    }
  }, [pickupDetails, isOpen])

  const focusToMarker = (latitude: number, longitude: number) => {
    const position = new mapsRef.current.LatLng(latitude, longitude)
    mapRef.current.panTo(position)
    mapRef.current.setZoom(14)
  }

  const handleMarkerClick = (marker: any, index: number) => {
    setSelectedBranch({ ...marker, index })
    focusToMarker(Number(marker.latitude), Number(marker.longitude))
  }

  const setBounds = () => {
    if (mapsRef?.current) {
      const bounds = new mapsRef.current.LatLngBounds()
      if (filteredBranches.length > 1) {
        filteredBranches
          .map(branch => ({ lat: Number(branch.latitude), lng: Number(branch.longitude) }))
          .forEach(marker => {
            bounds.extend(marker)
          })
        mapRef.current.fitBounds(bounds)
      } else {
        focusToMarker(Number(filteredBranches[0].latitude), Number(filteredBranches[0].longitude))
      }
    }
  }

  useEffect(() => {
    let filteredBranchesData: IBranchInfo[] = branches
    if (selectedRegion) {
      filteredBranchesData =
        pickupDetails?.branches
          ?.filter(item => item.region === selectedRegion?.value)
          .map(item => [...item.branches])
          .flat() || []
    } else {
      const northIslandBranches =
        pickupDetails?.branches
          ?.filter(region => region.island === 'North')
          ?.map(region => {
            return region?.branches
          })
          .flat()
          .sort((a, b) => {
            // Sort by latitude in ascending order
            if (Number(a.latitude) < Number(b.latitude)) return 1
            if (Number(a.latitude) > Number(b.latitude)) return -1
            return 0
          }) || []

      const southlandBranches =
        pickupDetails?.branches
          ?.filter(region => region.island === 'South')
          ?.map(region => {
            return region?.branches
          })
          .flat()
          .sort((a, b) => {
            // Sort by latitude in ascending order
            if (Number(a.latitude) < Number(b.latitude)) return 1
            if (Number(a.latitude) > Number(b.latitude)) return -1
            return 0
          }) || []

      const allBranches = [...northIslandBranches, ...southlandBranches]
      filteredBranchesData = allBranches
    }
    if (showOnlyStockAvailable) {
      filteredBranchesData = filteredBranchesData?.filter(item => item.stock > 0)
    }

    setFilteredBranches(filteredBranchesData)
  }, [branches, showOnlyStockAvailable, selectedRegion])

  useEffect(() => {
    if (filteredBranches.length > 0 && !isLoadingMaps) {
      setBounds()
    }
  }, [isLoadingMaps, mapRef.current, filteredBranches, isOpen])

  const showMoreAction = () => {
    setShowMore(!showMore)
  }

  const showOnlyStockAvailableAction = () => {
    setSelectedBranch(undefined)
    setShowOnlyStockAvailable(!showOnlyStockAvailable)
  }

  const onBranchSelection = (index: number, branch: IBranchInfo) => {
    setSelectedBranch({ ...branch, index })
    focusToMarker(Number(branch.latitude), Number(branch.longitude))
  }

  const onMobileBranchSelection = (branchOption: any) => {
    if (branchOption) {
      // Finding the index from the filtered branches for matching name
      const index = filteredBranches.findIndex(branch => branch.name === branchOption.value)
      const branch = filteredBranches[index]
      onBranchSelection(index, branch)
    } else {
      setSelectedBranch(undefined)
      setBounds()
    }
  }

  const onRegionSelection = (value: any) => {
    setSelectedBranch(undefined)
    setSelectedRegion(value)
  }

  const MarkerComponent = ({ key, label, lat, lng, onClick }: any) => {
    return (
      <button
        key={key}
        onClick={onClick}
        style={{ position: 'relative', transform: 'translate(-50%, -100%)' }}>
        <Image
          src={
            selectedBranch?.latitude === lat && selectedBranch?.longitude === lng
              ? mapMarker
              : greyMarker
          }
          width={42}
          height={50}
          alt={label ?? ''}
        />
      </button>
    )
  }

  const onOpeningGoogleMpas = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=NZ+Safety+Blackwoods+${selectedBranch?.street}`
    )
  }

  useEffect(() => {
    if (!isOpen) {
      setShowOnlyStockAvailable(true)
      setShowMore(false)
      setSelectedBranch(undefined)
      setSelectedRegion(undefined)
      setFilteredBranches([])
      setIsLoadingMaps(true)
    }
  }, [isOpen])

  const phoneNumberContent = selectedBranch && (
    <>
      <div className='text-xs text-B-500 font-medium'>Phone Number</div>
      <div
        tabIndex={0}
        role='button'
        onClick={() => {
          if (selectedBranch && selectedBranch?.phoneNumber?.length > 0) {
            window.location.href = `tel:${selectedBranch?.phoneNumber}`
          }
        }}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter' && selectedBranch && selectedBranch?.phoneNumber?.length > 0) {
            window.location.href = `tel:${selectedBranch?.phoneNumber}`
          }
        }}
        className={CN('text-sm text-N-700 font-normal', {
          'cursor-pointer': selectedBranch && selectedBranch?.phoneNumber?.length > 0
        })}>
        {selectedBranch?.phoneNumber}
      </div>
    </>
  )

  return (
    <div className={PickupModalClasses} {...restProps}>
      <Modal
        isOpen={isOpen}
        className='w-full !max-w-[396px] md:!max-w-[712px] lg:!max-w-[1280px]'
        onClickOverlay={close}>
        <div className='flex flex-row items-center justify-end h-[52px] px-[20px]'>
          <Button
            iconOnly
            iconBefore='nzsbi-close'
            appearance='link-gray'
            size='xs'
            onClick={close}
          />
        </div>
        <ModalBody className='overflow-hidden min-h-[663px] !h-[663px] md:!h-[591px] lg:!h-[559px] !pt-0'>
          {isPickUpDetailsLoading ? (
            <div className='w-full h-full flex items-center justify-center'>
              <Spinner loop />
            </div>
          ) : (
            <div className='flex flex-col md:!flex-row w-full h-full gap-[20px] lg:!gap-[32px]'>
              <div className='flex flex-col w-full md:!min-w-[239px] lg:!max-w-[296px] gap-[20px]'>
                {/* Product view */}
                <div className='flex w-full gap-[8px]'>
                  <div>
                    {/* Poduct thumnail */}
                    <div className='w-[58px] h-[58px]'>
                      <div className='pt-[100%] w-full relative block'>
                        <div className='absolute top-0 bottom-0 right-0 left-0'>
                          <NextImage
                            imgKey={`${sku}-${id}`}
                            width={200}
                            height={300}
                            imageUrl={`${image?.url}`}
                            alt={`${image?.alt || name}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='text-sm font-bold text-N-700'>{name}</div>
                    <span className='text-sm font-normal text-N-500'>Code: </span>
                    <span className='text-sm font-medium text-N-700'>{sku}</span>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <Select
                    size='sm'
                    data={pickupDetails ? (pickupDetails?.groupedSelectOptions as any) : []}
                    value={selectedRegion}
                    onChange={value => onRegionSelection(value)}
                    className='mb-[12px] md:mb-[20px]'
                    placeholder='Choose your Region'
                  />
                  <CheckBox
                    checked={showOnlyStockAvailable}
                    id='show-only-stock-available'
                    labelSize='sm'
                    onChange={showOnlyStockAvailableAction}
                    label='Only show branches with stock'
                    className='mb-[12px] md:mb-[16px]'
                  />
                  <div className='flex justify-between text-xs text-N-800 font-bold mb-[8px]'>
                    <span>BRANCH</span>
                    <span>STOCK</span>
                  </div>
                  <Select
                    size='sm'
                    data={
                      filteredBranches
                        ? filteredBranches?.map(branch => ({
                            label: branch.name,
                            value: branch.name,
                            secondaryLabel: branch.stock
                          }))
                        : []
                    }
                    onChange={(selection: any) => {
                      onMobileBranchSelection(selection)
                    }}
                    value={
                      selectedBranch
                        ? {
                            value: selectedBranch?.name,
                            label: selectedBranch?.name,
                            secondaryLabel: selectedBranch?.stock
                          }
                        : undefined
                    }
                    className='md:!hidden'
                  />

                  {/* Branch List */}
                  <div className='h-[367px] lg:max-h-[335px] overflow-y-auto hidden md:!block'>
                    {filteredBranches?.map((branch, index) => (
                      <button
                        onClick={() => onBranchSelection(index, branch)}
                        className={CN(
                          'flex w-full py-[12px] px-[8px] text-sm font-normal text-n-700',
                          {
                            'bg-B-500 text-white rounded': selectedBranch?.index === index
                          }
                        )}
                        key={`${branch.name ?? ''}-${index}`}>
                        <div className='flex w-full justify-between'>
                          <span className='text-start'>{branch?.name}</span>
                          <span>{branch.stock}</span>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className={CN(
                  'flex flex-col lg:!flex-row w-full relative h-[383px] md:!h-full overflow-y-auto',
                  {
                    'lg:!gap-[32px]': selectedBranch
                  }
                )}>
                <motion.div
                  initial={{ x: '-100%', width: 0, height: 'full' }}
                  animate={{
                    x: selectedBranch ? '0%' : '-100%',
                    width: selectedBranch ? '296px' : 0
                  }}
                  className='hidden lg:!block'
                  transition={{ duration: 0.5 }}>
                  {/* Content of the sliding div */}
                  <div className='flex flex-col w-full h-full md:!w-[413px] lg:!w-[296px] bg-B-25 mr-[20px] px-[20px] pt-[20px] pb-[16px] gap-[8px] absolute bottom-0 lg:!relative z-10 overflow-y-auto scrollbar-hide'>
                    <div className='text-h3-m md:text-h3 text-B-500 font-semibold'>
                      {selectedBranch?.name}
                    </div>
                    <div className='text-sm md:text-base text-b-600 font-normal'>
                      {selectedBranch &&
                        `${selectedBranch?.street ? selectedBranch?.street : ''} ${
                          selectedBranch?.suburb ? ', ' + selectedBranch?.suburb : ''
                        } ${selectedBranch?.city} ${
                          selectedBranch?.postCode ? ', ' + selectedBranch?.postCode : ''
                        }`}
                    </div>
                    <div className='flex justify-between lg:!pb-[30px] lg:!border-b lg:!border-N-200'>
                      <Button
                        appearance='secondary-orange'
                        iconAfter='nzsbi-external-link'
                        onClick={onOpeningGoogleMpas}>
                        OPEN IN GOOGLE MAPS
                      </Button>
                      <Button className='lg:!hidden' appearance='link' onClick={showMoreAction}>
                        {showMore ? 'Less Info' : 'More Info'}
                      </Button>
                    </div>

                    <div className='hidden lg:!block'>
                      <div className='flex flex-col py-[20px] border-b border-N-200 gap-[4px]'>
                        {phoneNumberContent}
                      </div>
                      {selectedBranch?.openTradeHours && (
                        <div className='flex flex-col py-[8px] md:!py-[16px] lg:!py-[20px] gap-[4px]  border-b border-N-200'>
                          <div className='text-xs text-B-500 font-medium'>Opening Hours</div>
                          <div className='flex flex-col gap-[8px] text-sm text-N-700 font-normal'>
                            {selectedBranch?.openTradeHours?.map((item, index: number) => (
                              <div
                                className='flex w-full gap-[20px]'
                                key={`${item.day ?? ''}-${index}`}>
                                <span className='min-w-[93px]'>{item?.day}</span>
                                <span>
                                  {item?.isOpened
                                    ? `${item?.openingTime} - ${item?.closingTime}`
                                    : 'Closed'}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {selectedBranch?.description && (
                        <div className='text-sm text-N-700 font-normal md:py-[16px] lg:!py-[20px]'>
                          <ul className='list-disc pl-[20px]'>
                            {selectedBranch?.description
                              .split('\\n')
                              .map((item, index) => <li key={`${item ?? ''}-${index}`}>{item}</li>)}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </motion.div>

                {selectedBranch && (
                  <div className='flex flex-col absolute bottom-0 lg:!relative z-10 gap-[8px] lg:!hidden bg-B-25 px-[20px] pt-[20px] pb-[16px]'>
                    <div className='text-h3-m md:text-h3 text-B-500 font-semibold'>
                      {selectedBranch?.name}
                    </div>
                    <div className='text-sm md:text-base text-b-600 font-normal'>
                      {selectedBranch &&
                        `${selectedBranch?.street ? selectedBranch?.street : ''} ${
                          selectedBranch?.suburb ? ', ' + selectedBranch?.suburb : ''
                        } ${selectedBranch?.city} ${
                          selectedBranch?.postCode ? ', ' + selectedBranch?.postCode : ''
                        }`}
                    </div>

                    <div className='flex justify-between lg:!pb-[30px] lg:!border-b lg:!border-N-200'>
                      <Button
                        appearance='secondary-orange'
                        iconAfter='nzsbi-external-link'
                        onClick={onOpeningGoogleMpas}>
                        OPEN IN GOOGLE MAPS
                      </Button>
                      <Button className='lg:!hidden' appearance='link' onClick={showMoreAction}>
                        {showMore ? 'Less Info' : 'More Info'}
                      </Button>
                    </div>
                    <AnimatePresence initial={false}>
                      <motion.div
                        className='overflow-hidden mt-[8px] lg:!hidden'
                        animate={{
                          height: showMore ? 'auto' : 0
                        }}>
                        <div className='flex flex-col py-[8px] md:!py-[16px] border-b border-t border-N-200 gap-[4px]'>
                          {phoneNumberContent}
                        </div>
                        {selectedBranch?.openTradeHours && (
                          <div className='flex flex-col py-[16px] gap-[4px] border-b border-N-200'>
                            <div className='text-xs text-B-500 font-medium'>Opening Hours</div>
                            <div className='flex flex-col gap-[8px] text-sm text-N-700 font-normal'>
                              {selectedBranch?.openTradeHours?.map((item, index: number) => (
                                <div
                                  className='flex w-full gap-[20px]'
                                  key={`${item?.day ?? ''}- ${index}`}>
                                  <span className='min-w-[93px]'>{item?.day}</span>
                                  <span>
                                    {item?.isOpened
                                      ? `${item?.openingTime} - ${item?.closingTime}`
                                      : 'Closed'}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {selectedBranch?.description && (
                          <div className='text-sm text-N-700 font-normal py-[16px] lg:!py-[20px]'>
                            <ul className='list-disc pl-[20px]'>
                              {selectedBranch?.description
                                .split('\\n')
                                .map((item, index) => (
                                  <li key={`${item ?? ''}-${index}`}>{item}</li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </motion.div>
                    </AnimatePresence>
                  </div>
                )}

                <div
                  className={CN(
                    'flex flex-col w-full   lg:!h-full md:!w-[413px] lg:!w-full bg-B-25 relative',
                    {
                      '!h-full bg-Red': !selectedBranch,
                      '!h-[267px] md:!h-[431px]': selectedBranch
                    }
                  )}>
                  <div className='w-full h-full'>
                    {branches.length > 0 && (
                      <GoogleMapReact
                        style={{
                          backgroundColor: '#fff'
                        }}
                        options={{
                          mapTypeControl: true,
                          zoomControl: true,
                          fullscreenControl: true
                        }}
                        bootstrapURLKeys={{
                          key: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY || ''
                        }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={8}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => {
                          mapRef.current = map
                          mapsRef.current = maps
                          setIsLoadingMaps(false)
                        }}>
                        {filteredBranches?.map((marker, index) => (
                          <MarkerComponent
                            onClick={() => handleMarkerClick(marker, index)}
                            key={marker.name}
                            lat={marker.latitude}
                            lng={marker.longitude}
                            text={marker.name}
                          />
                        ))}
                      </GoogleMapReact>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default PickupModal
