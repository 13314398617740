import React, { FC, useEffect } from 'react'
import { useCallMatrixFromFE } from 'lib/actions'
import { ILog, loggingRequest } from 'lib/actions/logger/loggingRequest'
import { useAppContext } from 'lib/contexts/app-context'
import LogLevel from 'lib/enums/logLevel'

export interface IPunchOutFormProps {
  cxmlHookURL?: string
  xml?: string
  cartItems?: any[]
  ociHookURL?: string
  ociToken?: string
}

export const PunchOutForm: FC<IPunchOutFormProps> = ({
  cxmlHookURL,
  xml,
  cartItems,
  ociHookURL,
  ociToken
}: IPunchOutFormProps) => {
  const isLoggingEnabled: boolean = !!process.env.NEXT_PUBLIC_LOGGING_ENABLED
  const { session } = useAppContext()

  const { callMatrixFromFE } = useCallMatrixFromFE()

  const handleMatrixCall = async (metricName: string, tags: string[]) => {
    await callMatrixFromFE({ metricName, tags })
  }

  useEffect(() => {
    if (xml !== '' && session?.punchOutMethod === 'cxml') {
      handleMatrixCall('punchOut.cxml.checkout', [
        `client:${session?.user?.data?.fullName || 'unknown'}`,
        `status:success`
      ])

      // Redact sensitive information from XML
      const redactedXML = xml
        ? xml.replace(
            /(<Credential\s+domain=")([^"]*)(">)(<Identity>)([^<]*)(<\/Identity>)(<\/Credential>)/g,
            '<Credential domain="******">$4******$6</Credential>'
          )
        : ''

      const log2: ILog = {
        level: LogLevel.Info,
        message: `PunchOut cXML Checkout Payload [XML]: ${isLoggingEnabled ? xml : redactedXML}`
      }
      loggingRequest(log2).catch(err => {
        console.error(err)
      })
      setTimeout(async () => {
        const form = document.getElementById('cxml_form') as HTMLFormElement
        form.submit()
      }, 2000)
    }
  }, [xml])

  useEffect(() => {
    if (cartItems && cartItems?.length > 0 && session?.punchOutMethod === 'oci') {
      handleMatrixCall('punchOut.oci.checkout', [
        `client:${session?.user?.data?.fullName || 'unknown'}`,
        `status:success`
      ])
      const log2: ILog = {
        level: LogLevel.Info,
        message: `OCI Token: ${ociToken} \nPunchOut OCI Checkout Payload [Cart Data]: ${JSON.stringify(cartItems)}`
      }
      loggingRequest(log2).catch(err => {
        console.error(err)
      })
      setTimeout(async () => {
        const form = document.getElementById('oci_form') as HTMLFormElement
        form.submit()
      }, 2000)
    }
  }, [cartItems])

  const queryParamsRemovedCxmlHookURL = cxmlHookURL?.split('?')[0] || ''
  const queryParamsRemovedOciHookURL = ociHookURL || ''

  const useOriginal: boolean = !!process.env.NEXT_PUBLIC_USE_ORIGINAL_CXML_URL
  const cxmlPostURL = useOriginal ? cxmlHookURL : queryParamsRemovedCxmlHookURL

  const log2: ILog = {
    level: LogLevel.Info,
    message: `Punch-Out Post CXML Constructed URL: ${queryParamsRemovedCxmlHookURL} \n
    Original URL: ${cxmlHookURL} \n
    Original URL: ${useOriginal} 
    `
  }
  loggingRequest(log2).catch(err => {
    console.error(err)
  })

  return (
    <>
      {/* CXML Form */}
      {session?.punchOutMethod === 'cxml' && (
        <form
          id='cxml_form'
          method='POST'
          action={cxmlPostURL}
          encType='application/x-www-form-urlencoded'>
          <input type='hidden' name='cXML-urlencoded' value={xml} />
        </form>
      )}
      {/* OCI Form */}
      {session?.punchOutMethod === 'oci' && (
        <form
          id='oci_form'
          method='POST'
          action={queryParamsRemovedOciHookURL}
          encType='application/x-www-form-urlencoded'>
          {cartItems?.map((item, index) => {
            return (
              <>
                <input type='hidden' name='~target' value='_self' maxLength={40} />
                <input type='hidden' name='returntarget' value='_self' maxLength={40} />
                <input
                  type='hidden'
                  name={`NEW_ITEM-DESCRIPTION[${index + 1}]`}
                  value={item.title}
                  maxLength={40}
                />
                <input
                  type='hidden'
                  name={`NEW_ITEM-QUANTITY[${index + 1}]`}
                  value={item.quantity}
                  maxLength={15}
                />
                <input
                  type='hidden'
                  name={`NEW_ITEM-UNIT[${index + 1}]`}
                  value={item.uom}
                  maxLength={3}
                />
                <input
                  type='hidden'
                  name={`NEW_ITEM-PRICE[${index + 1}]`}
                  value={item?.price?.toFixed(2)}
                  maxLength={15}
                />
                <input
                  type='hidden'
                  name={`NEW_ITEM-VENDORMAT[${index + 1}]`}
                  value={item.sku}
                  maxLength={15}
                />
                <input
                  type='hidden'
                  name={`NEW_ITEM-CURRENCY[${index + 1}]`}
                  value={item.currency}
                  maxLength={5}
                />
                <input
                  type='hidden'
                  name={`NEW_ITEM-LEADTIME[${index + 1}]`}
                  value={item.leadTime}
                  maxLength={5}
                />
                <input
                  type='hidden'
                  name={`NEW_ITEM-MATGROUP[${index + 1}]`}
                  value={item.materialGroup}
                  maxLength={40}
                />
                <input
                  type='hidden'
                  name={`NEW_ITEM-DOMAIN[${index + 1}]`}
                  value={item.domain}
                  maxLength={40}
                />
                <input
                  type='hidden'
                  name={`NEW_ITEM-UNSPSC[${index + 1}]`}
                  value={item.unspsc}
                  maxLength={40}
                />
                <input
                  type='hidden'
                  name={`NEW_ITEM-EXT_SCHEMA_TYPE[${index + 1}]`}
                  value={item.domain}
                  maxLength={40}
                />
                <input
                  type='hidden'
                  name={`NEW_ITEM-EXT_CATEGORY_ID[${index + 1}]`}
                  value={item.unspsc}
                  maxLength={40}
                />
              </>
            )
          })}
        </form>
      )}
    </>
  )
}

export default PunchOutForm
